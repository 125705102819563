import React, { createContext, useContext, useState, ReactNode } from "react";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";

import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "@/components/ui/drawer";

interface DialogContextType {
	openDialog: (content: ReactNode, dialogType?: string) => void;
	closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const useDialog = () => {
	const context = useContext(DialogContext);
	if (!context) {
		throw new Error("useDialog must be used within a DialogProvider");
	}
	return context;
};

export const DialogProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
	const [open, setOpen] = useState<boolean | false>(false);
	const [dialogType, setDialogType] = useState<string | null>(null);

	const openDialog = (content: ReactNode, type?: string) => {
		setOpen(true);
		setDialogContent(content);
		setDialogType(type || null);
	};

	const closeDialog = () => {
		setOpen(false);
		setDialogContent(null);
		setDialogType(null);
	};

	return (
		<DialogContext.Provider value={{ openDialog, closeDialog }}>
			{children}
			{dialogContent && (
				<>
					{dialogType == "drawer" ? (
						<Drawer open={open} onOpenChange={setOpen}>
							<DrawerTitle></DrawerTitle>
							<DrawerContent>{dialogContent}</DrawerContent>
						</Drawer>
					) : (
						<Dialog open={open} onOpenChange={setOpen}>
							<DialogTitle></DialogTitle>
							<DialogContent className="dialog-content-inner">
								{dialogContent}
							</DialogContent>
						</Dialog>
					)}
				</>
			)}
		</DialogContext.Provider>
	);
};
