// import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";

const NotMobile = (props: any) => {
  return (
    <>
      <p className="text-5xl font-medium text-center">
        Supercharge is only available in mobile view.
      </p>
      <p className="text-xxl mt-6 font-medium">
        Scan the QR code to access it on your phone
      </p>
      <div
        className="mt-16 flex justify-center items-center relative"
        style={{ height: "240px", width: "240px" }}
      >
        {/* QR Code */}
        <QRCode
          size={200}
          style={{
            height: "200px",
            width: "200px",
          }}
          value={window.location.href}
          qrStyle="dots"
          eyeRadius={5}
        />

        {/* Logo Background Circle */}
        <div
          className="absolute flex justify-center items-center"
          style={{
            backgroundColor: "#fff", // Background color of the circle
            borderRadius: "50%", // Makes it a perfect circle
            height: "60px", // Adjust height of the circle
            width: "60px", // Adjust width of the circle
          }}
        >
          {/* Logo */}
          <img
            src="https://app.superchar.xyz/static/media/logo_black.5feb3ec45686c96b2df7ddbb62d5e4c9.svg"
            alt="Logo"
            style={{
              height: "30px", // Adjust logo height
              width: "20px", // Adjust logo width
            }}
          />
        </div>
      </div>
    </>
  );
};

export default NotMobile;
