import { io } from "socket.io-client";
import config from "./config";
import appHelper from "@/helpers/appHelper";

const ioUrl = config.baseUrl;
// const ioUrl = "http://localhost:6789";
const user_id = appHelper.getUserInfo("user_id")
	? appHelper.getUserInfo("user_id")
	: appHelper.getUserInfo("id");

var socket = user_id
	? io(ioUrl, {
			query: { user_id: user_id.toString() }, // Send userId in the query
	  })
	: io(ioUrl);

export default socket;
