import moment from "moment";
import appConst from "../consts/appConst";
import config from "../services/config";

function getUserInfo(key) {
	let business = window.localStorage.getItem("scuser");
	if (!business) return null;
	try {
		business = JSON.parse(business);
		if (!business) return null;
		return business[key];
	} catch (error) {
		return null;
	}
}

function ConvertStringToHex(str) {
	var arr = [];
	for (var i = 0; i < str.length; i++) {
		arr[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
	}
	return "\\u" + arr.join("\\u");
}

function getImageUrl(url) {
	if (!url) return null;
	if (url?.toString().includes("http")) return url;

	return config.baseUrl + "/" + url;
}

function getAbsUrl(url) {
	if (!url) return null;
	if (url.includes("http")) return url;

	return "http://" + url;
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function makeid(length) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
		counter += 1;
	}
	return result;
}

const requirementType = {
	CONTENT: "CONTENT",
	QRCODE: "QRCODE",
	CHECKIN: "CHECKIN",
	WEB3: "WEB3",
	SOCIAL: "SOCIAL",
	VR: "VR",
	AR: "AR",
	FORM: "FORM",
	QUIZ: "QUIZ",
	LOCATION: "LOCATION",
	VALIDATION: "VALIDATION",
};

const requirementExp = {
	CONTENT: 50,
	QRCODE: 50,
	CHECKIN: 75,
	WEB3: 100,
	SOCIAL: 50,
	VR: 100,
	AR: 100,
	FORM: 50,
	QUIZ: 75,
	LOCATION: 100,
};

const stationType = {
	BADGE: "BADGE",
	MEMBERTIER: "MEMBERTIER",
	NFT: "NFT",
	ITEM: "ITEM",
};

const benefitTypes = {
	discount: "discount",
	privateMission: "privateMission",
};

const getCoords = async () => {
	const pos = await new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(resolve, reject, {
			enableHighAccuracy: true,
		});
	});

	return {
		long: pos.coords.longitude,
		lat: pos.coords.latitude,
	};
};

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	result.forEach((element, index) => {
		element.position = index;
	});

	return result;
};

const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

function getItemQuality(quality) {
	let obj = appConst.productQuality.find((ele) => ele.key == quality);
	if (obj) return obj;
	return null;
}

function getCurrentClub() {
	let club = window.localStorage.getItem("current_club");
	if (!club) return null;
	try {
		club = JSON.parse(club);
		return { ...club };
	} catch (error) {
		return null;
	}
	return null;
}

function setCurrentClub(club) {
	if (!club.id) return;
	window.localStorage.setItem("current_club", JSON.stringify(club));
}

function addItemToArray(arr, item) {
	if (!arr) arr = [];
	let index = arr?.find((ele) => ele.id == item.id || ele == item);
	if (!index) {
		arr?.push(item);
	}
	return arr;
}

function removeItemFromArray(arr, item) {
	if (!arr) arr = [];
	let index = arr?.find((ele) => ele.id == item.id || ele == item);
	if (index) {
		arr?.splice(index, 1);
	}

	return arr;
}

function formattedAmount(amount) {
	return amount.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});
}
function getRemainTime(targetDate) {
	if (!targetDate) {
		console.error("Target date is required.");
		return null;
	}

	const now = moment();
	const targetMoment = moment(targetDate);

	// Validate the target date
	if (!targetMoment.isValid()) {
		console.error("Invalid target date.");
		return null;
	}

	// Calculate time difference
	const duration = moment.duration(targetMoment.diff(now));

	// Extract days, hours, minutes, and seconds
	const days = Math.floor(duration.asDays());
	const hours = Math.floor(duration.hours()); // Directly from duration
	const minutes = Math.floor(duration.minutes());
	const seconds = Math.floor(duration.seconds());

	// Handle past dates
	if (duration.asMilliseconds() <= 0) {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			formatted: "0d 0h 0m 0s",
		};
	}

	// Return the formatted time object
	return {
		days,
		hours,
		minutes,
		seconds,
		formatted: `${days}d ${hours}h ${minutes}m ${seconds}s`,
	};
}

function getAuctionPrice(auction) {
	let auctionPrice =
		auction.bid_history?.length > 0
			? auction.bid_history[auction.bid_history?.length - 1].amount
			: auction.start_amount;
	return auctionPrice ? auctionPrice : 0;
}

function hasHtmlTags(content) {
	const htmlTagsRegex = /<[^>]*>/;
	return htmlTagsRegex.test(content);
}

async function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
	});
}

const replacer = (key, value) => {
	if (typeof value === "string") {
		return value.replace(/\\/g, "\\\\").replace(/"/g, '\\"');
	}
	return value;
};

function getMediaUrl(imageUrl) {
	return imageUrl.split("?")[0];
}

export default {
	ConvertStringToHex: ConvertStringToHex,
	getUserInfo: getUserInfo,
	getImageUrl: getImageUrl,
	delay: delay,
	getAbsUrl: getAbsUrl,
	makeid: makeid,
	requirementType: requirementType,
	stationType: stationType,
	getCoords: getCoords,
	reorder: reorder,
	readFile: readFile,
	getItemQuality: getItemQuality,
	getCurrentClub: getCurrentClub,
	setCurrentClub: setCurrentClub,
	addItemToArray: addItemToArray,
	removeItemFromArray: removeItemFromArray,
	formattedAmount: formattedAmount,
	getItemQuality: getItemQuality,
	getRemainTime: getRemainTime,
	getAuctionPrice: getAuctionPrice,
	hasHtmlTags: hasHtmlTags,
	benefitTypes: benefitTypes,
	getBase64: getBase64,
	replacer: replacer,
	getMediaUrl: getMediaUrl,
	requirementExp: requirementExp,
};
