export default {
	role: {
		OWNER: "owner",
		MEMBER: "member",
		GUEST: "guest",
		ADMIN: "admin",
	},
	productQuality: [
		{
			key: "urare",
			value: 1,
			name: "Ultra Rare",
			color: "#66FF59",
			linearBG:
				"linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.69) 50%, rgba(100, 240, 84, 0.50) 100%)",
		},
		{
			key: "ssrare",
			value: 3,
			name: "SS.Rare",
			shortName: "SS.Rare",
			color: "#8E49FF",
			linearBG:
				"linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.69) 50%, rgba(142, 73, 255, 0.50) 100%)",
		},
		{
			key: "srare",
			value: 7,
			name: "Super Rare",
			color: "#FFB45B",
			linearBG:
				"linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.69) 50%, rgba(230, 140, 5, 0.50) 100%);",
		},
		{
			key: "rare",
			value: 19,
			name: "Rare",
			color: "#02D7F2",
			linearBG:
				"linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.69) 50%, rgba(2, 215, 242, 0.50) 100%);",
		},
		{
			key: "uncommon",
			value: 40,
			name: "Uncommon",
			color: "#FF6969",
			linearBG:
				"linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.69) 50%, rgba(255, 112, 112, 0.50) 100%)",
		},
		{
			key: "common",
			value: 30,
			name: "Common",
			color: "#FFA500",
			linearBG:
				"linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.69) 50%, rgba(149, 149, 149, 0.50) 100%)",
		},
	],
	mapBoxAccessToken:
		"pk.eyJ1IjoiZW1teXRlbyIsImEiOiJjbHA3c2I5ZDgwN3oxMmx0ZG92Zmwwd2w0In0.bxLIuugWD9z2BnsVfazZng",
	mapStyle: {
		width: "100%",
		height: "100%",
		minHeight: 400,
	},
	requirementType: [
		{ name: "CONTENT", value: "CONTENT" },
		{ name: "QRCODE", value: "QRCODE" },
		{ name: "CHECKIN", value: "CHECKIN" },
		{ name: "WEB3", value: "WEB3" },
		{ name: "SOCIAL", value: "SOCIAL" },
		{ name: "VR", value: "VR" },
		{ name: "AR", value: "AR" },
		{ name: "FORM", value: "FORM" },
		{ name: "QUIZ", value: "QUIZ" },
		{ name: "LOCATION", value: "LOCATION" },
	],
};
